<template>
	<div id="emailslist">

		<collapse :selected="!checkIfHasChilds(emailList.children) || false">
			<div slot="collapse-header">
				<span class="collapseHeader">{{formatEmailHeader(emailList)}}
					<span class="emailExcerpt">{{formatEmailExcerpt(emailList.body)}}</span>
				</span>
				<span class="cllapsTime">
					<span class="emailDatePrefix">{{ $moment(emailList.date * 1000).format("Do MMM H:mm") }}</span>
					({{$moment(emailList.date * 1000).fromNow()}})</span>
			</div>
			<div slot="collapse-body">
				<div class="emailBody">
					<p class="bodyText" v-html="!emailList.body ? $t('components.empty-msg') : emailList.body">
					</p>
					<span v-if="!checkIfHasChilds(emailList.children) || false" style="padding: 0 0 0 20px !important;">
						<el-button class="show-reply-form" type="primary" size="mini" icon="el-icon-message" plain
							@click.native="showReplyForm = !showReplyForm">{{$t('components.respond')}}</el-button>
					</span>
					<div v-for="(attachment, i) in emailList.hasAttachments" :key="i">
						<el-button class="download-attch" @click="downloadAttachment(emailList.id_email, attachment)">Atasament: {{attachment}}
						</el-button>
					</div>
				</div>
				<transition name="fade">
					<div v-if="showReplyForm" class="emailList replyContainer">
						<Editor :text.sync="replyText"></Editor>
						<div class="replyButton">
							<el-button class="send-reply" type="primary" size="mini" icon="el-icon-message" plain
								@click.native="sendReply">{{$t('components.send')}}</el-button>
						</div>
					</div>
				</transition>
			</div>
		</collapse>
		<EmailList v-for="subEmail in emailList.children" :data="subEmail" :key="subEmail.generated" :cemail="subEmail"
			:parent="false" :order="currentOrder"></EmailList>

	</div>
</template>

<script>

	function cutString(text) {
		const wordsToCut = 9;
		const wordsArray = text.split(' ');
		if (wordsArray.length > wordsToCut) {
			let strShort = '';
			for (let i = 0; i < wordsToCut; i++) {
				strShort += wordsArray[i] + ' ';
			}
			return strShort + '...';
		} else {
			return text;
		}
	}
	import Collapse from 'vue-collapse';
	import {
		EventBus
	} from '@/utils/Bus.js';

	import size from 'lodash.size';

	import {
		sendOrderCustomEmail,
		downloadOrderEmailAttachment
	} from '@/api/email';

	const Editor = () => import('@/components/Editor');

	export default {
		name: 'EmailList',
		components: {
			Collapse,
			Editor
		},
		data() {
			return {
				emailList: null,
				bindingList: null,
				isParent: null,
				showReplyForm: false,
				replyText: '',
				msgId: null,
				currentOrder: null,
			};
		},
		mounted: function() {},
		methods: {
			downloadAttachment: function(emailID, attachmentNumber) {
				downloadOrderEmailAttachment({
					emailID: emailID,
					attachmentNumber: attachmentNumber
				}).then((res) => {
					const hiddenElement = document.createElement('a');
					hiddenElement.href = 'data:' + res.message.mime + ';base64,' + res.message.attachment;
					hiddenElement.target = '_blank';
					hiddenElement.download = res.message.filename;
					hiddenElement.click();
				});
			},
			formatEmailSubject: function(subject) {
				return this.$t('components.subject') + ': ' + subject;
			},
			checkIfHasChilds: function(childs) {
				return size(childs) || false;
			},
			formatEmailHeader: function(email) {
				return email.type === 'to' ? email.affiliateName : email.clientName;
			},
			formatEmailExcerpt(body) {
				const temp = document.createElement('div');
				temp.innerHTML = body;
				const sanitized = temp.textContent || temp.innerText;
				return !body ? this.$t('components.empty-msg') : cutString(sanitized);
			},
			sendReply() {
				const vm = this;
				if (this.replyText.length < 5) {
					this.sbMsg({
						message: this.$t('components.error-email'),
						type: 'error'
					});
					return false;
				}

				const params = {
					cmd: this.currentOrder,
					subject: this.emailList.subject,
					body: this.replyText,
					replyID: this.emailList.message_id
				};
				this.isLoading = true;
				sendOrderCustomEmail(params).then((res) => {
					vm.showReplyForm = false;
					vm.replyText = '';
					EventBus.$emit('replySent', true);
				}).catch((err) => {
					this.$reportToSentry(err, {
						extra: {
							fn: 'sendOrderCustomEmail',
							params
						}
					});
				});
			}
		},
		props: {
			cemail: {
				type: Object,
				required: true
			},
			parent: {
				type: Boolean,
				required: true
			},
			order: {
				type: null,
				required: true
			}
		},
		created() {
			this.emailList = this.cemail;
			this.isParent = this.parent;
			this.currentOrder = this.order;
		}
	};
</script>

<style lang="css">
	.emailBody {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-align: center;
		align-items: center;
	}

	.emailBody .bodyIcon {
		display: table-cell;
		vertical-align: middle;
		width: 1%;
		padding-right: 10px;
	}

	.emailBody .subEmails {
		display: table-cell;
		vertical-align: middle;
	}

	.bodyIcon .fa {
		vertical-align: middle;
		webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	.bodyText {
		display: inline-block;
		vertical-align: middle;
		/*padding-left: 10px;*/
	}

	.collapse-header.touchable {
		cursor: pointer;
	}

	.collapse .collapse-header::before {
		top: calc(50% - 1em) !important;
	}

	.collapse .collapse-header {
		padding: 10px 10px 10px 40px !important;
		background: rgba(#f2f2f2, 0.92) !important;
		border-top: 1px solid #d7d7d7;
		/*border-bottom: 1px solid #d1d1d1;*/
		border-radius: 0 !important;
	}

	.collapseHeader {
		font-weight: 500;
		font-size: 0.85em;
		color: black;
	}

	.cllapsTime {
		font-size: 0.8em;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.78);
	}

	.collapse .collapse-content-box {
		padding: 15px 20px !important;
		font-size: 13px;
	}

	.collapse {
		margin-bottom: 0 !important;
	}

	.emailExcerpt {
		color: rgba(0, 0, 0, 0.6);
		font-weight: 400;
		padding-left: 5px;
	}

	.emailDatePrefix {
		text-transform: capitalize;
	}

	.emailList.replyContainer {
		margin-top: 10px;
		display: table;
		width: 100%;
	}

	.ql-tooltip.ql-editing {
		left: 0 !important;
	}

	/*.replyContainer .sendReplyBtn {
  margin: 0px 0px 0px 20px !important;
  display: table-cell;
  vertical-align: middle;
  float: none;
}

.replyContainer .quill-editor {
  display: table-cell;
  vertical-align: middle;
}*/

	.replyButton {
		margin-top: 20px
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .2s
	}

	.fade-enter,
	.fade-leave-to
	/* .fade-leave-active below version 2.1.8 */

		{
		opacity: 0
	}
</style>